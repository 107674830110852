import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { useTranslation, Trans } from 'react-i18next';

function Tnc() {
  const location = useLocation();
  const { md } = useParams();
  const { t, i18n } = useTranslation();

  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    let mdPath = `/md/${md}.md`

    if (i18n.language !== 'en') {
      mdPath = `/md/${md}-${i18n.language}.md`
    }
    console.log(mdPath)
    fetch(mdPath)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  }, [i18n.language]);
  return (
    <>
      <Helmet>
        <title>Terms of Use</title>
      </Helmet>
      <main className="container-fluid">
        <div className="px-4 py-5 my-5 text-justify">
          <div className="col-lg-6 mx-auto card text-dark p-4 md-body">
            <ReactMarkdown>{markdown}</ReactMarkdown>
          </div>
        </div>
      </main>
    </>
  );
}

export default Tnc;
