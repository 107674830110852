import { useRef, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import {
  Button, Form, FormControl, FormLabel, Spinner, InputGroup,
} from 'react-bootstrap';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import StatusAlert from '../components/StatusAlert';
import { emailPattern } from '../common/constants';

import './auth.css';

function ForgotPassword() {
  const { t, i18n } = useTranslation();
  const context = useOutletContext();
  const title = 'Forgot Password';

  const [isLoading, setIsLoading] = useState(false);
  const [coolDown, setCoolDown] = useState(0);
  const navigate = useNavigate();
  const { sendPasswordReset, sendConfirmCode } = useAuth();
  const {
    register, handleSubmit, formState: { errors }, watch,
  } = useForm();

  const alertOpts = useRef({ isShow: false, variant: 'success', message: '' });

  const handleDismiss = () => {
    alertOpts.current.isShow = false;
  };

  const handleSend = async (data) => {
    try {
      setIsLoading(true);
      await sendPasswordReset(data);

      context.setAlertOpts({
        text: 'Password Updated',
        icon: 'success',
        timer: 5000,
      });
      setIsLoading(false);
      navigate('/login');
    } catch (err) {
      console.log('err', err)
      context.setAlertOpts({
        text: err.error,
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  const getConfirmCode = async (email) => {
    try {
      setIsLoading(true);
      setCoolDown(60)
      await sendConfirmCode({ email, lang: i18n.language });
      context.setAlertOpts({
        text: 'Confirmation Code sent',
        icon: 'success',
        timer: 5000,
      });
      setIsLoading(false);
    } catch (err) {
      context.setAlertOpts({
        text: 'Sent Confirmation Code Fail',
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  const data = {
    email: watch('email'),
    confirmCode: watch('confirmCode'),
    password: watch('password'),
  };

  useEffect(() => {
    if (coolDown > 0) {
      setTimeout(() => {
        setCoolDown(coolDown - 1);
      }, 1000);
    }
  }, [coolDown]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container mt-5 p-5" style={{ minHeight: '70vh' }}>
        <Form className="row g-2" noValidate>
          <div className="col-md-3" />
          <div className="col-md-6 card rounded-3 bg-tr-dark p-4">
            <h3 className="mb-3 fw-normal text-center">{t('login-forgot-password')}</h3>
            <Form.Group controlId="inputEmail">
              <FormLabel>{t('login-email')}</FormLabel>
              <FormControl type="email"
                isInvalid={errors.email}
                placeholder="Email@domain.com"
                {
                ...register('email', {
                  required: true,
                  pattern: emailPattern,
                })
                }
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.type === 'required' && 'Email is required'}
                {errors.email?.type === 'pattern' && 'Invalid email'}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="confirmCode" className='mt-3'>
              <FormLabel>{t('confirmation-code')}</FormLabel>
              <InputGroup>
                <FormControl type="input"
                  isInvalid={errors.confirmCode}
                  {
                  ...register('confirmCode', {
                    required: true,
                  })
                  }
                />
                <Button className='btn btn-secondary' id="button-addon2"
                  onClick={() => {
                    getConfirmCode(data.email)
                  }}
                  disabled={isLoading || coolDown > 0}

                >
                  {coolDown === 0 && <><i className="bi bi-key-fill"></i> {t('get')}{t('confirmation-code')}</>}
                  {coolDown > 0 && <><i className="bi bi-clock"></i> {coolDown}s</>}
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />

                </Button>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {errors.confirmCode?.type === 'required' && 'Confirmation Code is required'}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="inputPassword" className='mt-3'>
            <FormLabel>{t('signup-password')}</FormLabel>
            <FormControl type="password"
              isInvalid={errors.password}
              placeholder={t('signup-password')}
              {
              ...register('password', {
                required: true,
                minLength: 8,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.type === 'required' && t('signup-err-06')}
              {errors.password?.type === 'pattern' && t('signup-err-07')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="inputConfirmPassword" className='mt-3'>
            <FormLabel>{t('tp-confirm-purchase-btn')} {t('signup-password')}</FormLabel>
            <FormControl type="password"
              isInvalid={errors.confirmPassword}
              placeholder={`${t('tp-confirm-purchase-btn')} ${t('signup-password')}`}
              {
              ...register('confirmPassword', {
                required: true,
                minLength: 8,
                validate: (value) => (
                  value === data.password || t('signup-err-08')
                ),
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
            </Form.Control.Feedback>
          </Form.Group>
            <Button className="w-100 btn btn-lg btn-primary mt-3"
              type="button"
              disabled={isLoading}
              onClick={handleSubmit(handleSend)}
            >
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
              <span className="px-2">{t('reset')}{t('signup-password')}</span>
            </Button>
          </div>
          <div className="col-md-3" />
        </Form>
      </main>
      <StatusAlert show={alertOpts.current.isShow}
        variant={alertOpts.current.variant}
        message={alertOpts.current.message}
        onDismiss={handleDismiss}
      />
    </>
  );
}

export default ForgotPassword;
