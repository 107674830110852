import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

function Home() {
  const { t } = useTranslation();

  const title = 'Big Boss Generation';

  const navigate = useNavigate();
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        // Handle error if the video doesn't play, such as user interaction required
        console.error('Video playback failed:', error);
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid fade-in">
        <div className="px-4 py-0 text-center home-content">
          <div className="col-12 mx-auto pt-4">
            <div className='row fs-1 bg-tr-dark p-2 mt-5' style={{ height: window.innerWidth >= 640 ? '60vh' : ((window.innerWidth / 16) * 8) + 'px' }}>
              <video controls autoPlay playsInline defaultMuted muted style={{ maxHeight: '100%', width: 'auto' }}
                className='mx-auto'>
                <source src='/images/BigBossVideo3_Short.mp4' type='video/mp4' />
              </video>
            </div>
            <div className='row fs-1' style={{ height: window.innerWidth >= 640 ? '60vh' : '420px' }}>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-md-center align-self-end mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/about')}
                ><Trans i18nKey='home-about' /></div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-center mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/buy')}
                ><Trans i18nKey='home-apply' /></div>
              </div>
              <div className="col-md-4 col-sm-12 d-flex justify-content-center mb-md-5 glow-link">
                <div className='align-self-md-center align-self-sm-start mb-md-5 home-link text-chaman'
                  onClick={() => navigate('/contact')}
                >{t('home-contact')}</div>
              </div>
            </div>
            <div className='row fs-1 mt-0 mb-5 p-2 bg-tr-dark' style={{ height: 'auto' }}>
              <div className="col-12 justify-content-center p-3 pb-0 my-0">
                <h2 className='text-chaman'>{t('metaverse')}</h2>
                <p style={{ fontSize: window.innerWidth >= 640 ? '24px' : '16px', fontWeight: 'lighter' }}>
                  <Trans i18nKey='metaverse-text' />
                </p>
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-center mb-5">
                <a href='https://meta.bigbossgeneration.com/NightLife/' target='_blank'>
                  <img src='/images/Bboss_Metaverse-Webpage.gif' className='img-fluid rounded' />
                </a>
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-center mb-5">
                <img src='/images/Bboss_Metaverse-App.gif' className='img-fluid rounded' />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Home;
