import axios from 'axios';
import * as config from '../config'

async function createCheckoutSession(payload) {
  try {
    const response = await axios.post(`${config.API_URL}/payment/create-checkout-session`, payload);
    if (!response?.data?.url) {
      throw response
    }
    return response.data; // Return the response data
  } catch (error) {
    throw error.response ? error.response.data : new Error('failed');
  }
}

async function getPaymentStatus(payload) {
  try {
    const response = await axios.post(`${config.API_URL}/payment/get-payment-status`, payload);
    return response.data; // Return the response data
  } catch (error) {
    throw error.response ? error.response.data : new Error('failed');
  }
}

async function getTBTBalance(walletAddress) {
  try {
    const response = await axios.get(`${config.API_URL}/payment/getTBTBalance/${walletAddress}`);
    return response.data?.TBTBalance; // Return the response data
  } catch (error) {
    throw error.response ? error.response.data : new Error('failed');
  }
}

export {
  createCheckoutSession, getPaymentStatus, getTBTBalance,
};
