import React, { useEffect } from 'react';

const CoinGeckoWidget = () => {
  useEffect(() => {
    // Dynamically add the script tag for the CoinGecko widget
    const script = document.createElement('script');
    script.src = 'https://widgets.coingecko.com/gecko-coin-list-widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script tag when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {/* Render the widget */}
      <gecko-coin-list-widget
        locale='en'
        outlined='true'
        coin-ids='bitcoin,ethereum,tether'
        initial-currency='hkd'
      ></gecko-coin-list-widget>
    </div>
  );
};

export default CoinGeckoWidget;
