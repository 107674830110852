import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from 'store';
import useAuth from '../hooks/useAuth';

import './sidebar.css';

function Sidebar() {
  const { i18n, t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  // Function to change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const items = [
    { path: '/console', title: t('sb-dashboard'), icon: 'bi-house-door' },
    { path: '/console/buy', title: t('sb-purchase'), icon: 'bi-basket2' },
    { path: '/console/buy/hist', title: t('token-purchase-history-title'), icon: 'bi-card-checklist' },
    { path: '/console/eventcal', title: t('upcoming-events-title'), icon: 'bi-layers' },
    // { path: '/console/users', title: 'Users', icon: 'bi-people' },
    // { path: '/console/settings', title: 'Settings', icon: 'bi-gear' },
  ];

  return (
    <>
      <div className="position-stickyx pt-5">
        <ul className="nav flex-column">
          {
            items.map((item, i) => (
              <li key={i} className="nav-item"
                onClick={(e) => {
                  e.preventDefault();
                  if (window.innerWidth <= 640) {
                    document.querySelectorAll('.navbar-toggler')?.[0]?.click()
                  }
                }}
              >
                <NavLink className="nav-link" end to={item.path}>
                  <i className={`bi ${item.icon} pe-2`} />
                  {item.title}
                </NavLink>
              </li>
            ))
          }
          <hr />
          <li className="nav-item"
            onClick={(e) => {
              e.preventDefault();
              changeLanguage(i18n.language === 'en' ? 'zh' : 'en');
              store.set('lang', i18n.language);
              if (window.innerWidth <= 640) {
                document.querySelectorAll('.navbar-toggler')?.[0]?.click()
              }
            }}
          >
            <a className="nav-link" href="#"><i className="bi bi-globe pe-2"></i>{i18n.language === 'en' ? '中文' : 'English'}</a>
          </li>
          <li className="nav-item"
            onClick={async (e) => {
              e.preventDefault();
              await logout();
              navigate('/');
            }}
          >
            <a className="nav-link" href="#"><i className="bi bi-box-arrow-right pe-2"></i>{t('sign-out')}</a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
