import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import * as config from '../config'

const TransactionHist = ({ customerId }) => {
  const { t, i18n } = useTranslation();

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${config.API_URL}/payment/getPaymentHistory/${customerId}`);
        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
      setIsLoading(false);
    };

    fetchTransactions();
  }, [customerId]);

  if (isLoading) return (<div class="spinner-border spinner-border-lg text-secondary ms-2" role="status"></div>)
  if (!transactions || transactions.length === 0) return (<div>{t('th-none')}</div>)

  return (
    <div className='container p-2'>
      <div className="row py-2 border-bottom d-none d-md-flex">
        {/* Payment Intent */}
        <div className="col-md-6">
          <label className='text-secondary'>{t('th-id')}</label>
        </div>

        {/* Amount */}
        <div className="col-md-3">
          <label className='text-secondary'>{t('th-amt')}</label>
        </div>

        {/* Created Date */}
        <div className="col-md-3">
          <label className='text-secondary'>{t('th-date')}</label>
        </div>
      </div>
      {transactions && transactions.map((transaction) => (
        <div key={transaction.id} className="row py-2 border-bottom">
          {/* Payment Intent */}
          <div className="col-12 col-md-6">
            <label className='text-secondary d-md-none'>{t('th-id')}:</label>
            <p>{transaction.payment_intent}</p>
          </div>

          {/* Amount */}
          <div className="col-6 col-md-3">
            <label className='text-secondary d-md-none'>{t('th-amt')}:</label>
            <p>{(transaction.amount / 100).toFixed(2)} {transaction.currency.toUpperCase()}</p>
          </div>

          {/* Created Date */}
          <div className="col-6 col-md-3">
            <label className='text-secondary d-md-none'>{t('th-date')}:</label>
            <p>{new Date(transaction.created * 1000).toLocaleDateString()}</p>
          </div>
        </div>
      ))}

    </div>
  );
};

export default TransactionHist;
