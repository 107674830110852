import { useState, useEffect } from 'react';
import { ZkMeWidget, type Provider, verifyKycWithZkMeServices } from '@zkmelabs/widget'
import { Helmet } from 'react-helmet';
import QRCode from 'qrcode'
import {
  useOutletContext,
} from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import TransactionHist from '../components/TransactionHist';
import useAuth from '../hooks/useAuth';
import { getTBTBalance } from '../services/PaymentService';

import '@zkmelabs/widget/dist/style.css'

function Dashboard() {
  const { t, i18n } = useTranslation();
  const title = t('sb-dashboard');
  const { getSession, kycToken, syncKYC } = useAuth();
  const user = getSession();
  const context = useOutletContext();
  const [walletQR, setWalletQR] = useState(null)
  const [balance, setBalance] = useState(0);
  console.log('user', user)

  const memTier = {
    bronze: <h3 className="text-bronze"><Trans i18nKey={'mu-BRONZE'} /></h3>,
    silver: <h3 className="text-silver"><Trans i18nKey={'mu-SILVER'} /></h3>,
    gold: <h3 className="text-gold"><Trans i18nKey={'mu-GOLD'} /></h3>,
    none: <h3 className="text-white"><Trans i18nKey={'mu-NONE'} /></h3>,
  }

  const kycConfig = {
    appId: 'M2024091409404354301300896914503',
    programNo: '202409270001',
  }

  const [kycResult, setKycResult] = useState('Press "Complete KYC Now" to start');
  const [kycStatusReloading, setKycStatusReloading] = useState(false);

  useEffect(() => {
    (async () => {
      const qr = await QRCode.toDataURL(user.metadata?.solana_wallet_address)
      setWalletQR(qr)
      getTBTBalance(user.metadata?.solana_wallet_address).then(setBalance);
    })()
  }, [user.metadata?.solana_wallet_address])

  const provider: Provider = {

    async getAccessToken() {
      return kycToken()
    },

    async getUserAccounts() {
      return [user.metadata?.solana_wallet_address ?? user.email ?? user.phone ?? user.id]
    },
  }

  const zkMeWidget = new ZkMeWidget(
    kycConfig.appId, // This parameter means the same thing as "mchNo"
    'bigbossgeneration',
    '0x89',
    provider,
    {
      lv: 'zkKYC',
      programNo: kycConfig.programNo,
      locale: i18n.language === 'en' ? 'en' : 'zh-hk',
    },
  )

  const getKYVisGrant = async () => {
    const results = await verifyKycWithZkMeServices(
      kycConfig.appId,
      user.metadata?.solana_wallet_address,
    )
    return results
  }

  async function handleFinished(results) {
    reloadKYC();
    setKycResult(JSON.stringify(results, null, 2))
    const { isGrant, associatedAccount } = results
    if (
      isGrant
      && associatedAccount === user.metadata?.solana_wallet_address
    ) {
      // TODO
    }
  }

  const reloadKYC = async () => {
    setKycStatusReloading(true)
    const updatedUser = await syncKYC(user.id)
    if (updatedUser?.id) {
      localStorage.setItem('authx.user', JSON.stringify({ ...updatedUser, token: user.token }));
    }
    setKycStatusReloading(false)
    context.setAlertOpts({
      text: 'KYC Status Refreshed!',
      icon: 'success',
    });
  }

  zkMeWidget.on('kycFinished', handleFinished)

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid p-0">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title}</h1>
        </div>
        <div
          className="row g-0 border rounded flex-md-row mb-4 shadow-sm h-md-250 position-relative card">
          <div className="col-auto">
            <div className="pt-3 px-3 text-center">
              {walletQR && <img src={walletQR} width={200} height={200} className='fade-in border border-3 rounded-3' />}
            </div>
            <div className="pt-3 px-3 text-center">
              <label>{t('membership-tier')}</label><br />
              <div className='rounded p-2' style={{ backgroundColor: '#000D' }}>
                {memTier[user.metadata?.membership ?? 'none']}
              </div>
            </div>
            {user.metadata?.refCode && <div className="pt-3 px-3 text-center">
              <label>{t('refCode')}</label><br />
              <div className='rounded p-2 text-white cursor-pointer' style={{ backgroundColor: '#000D', fontSize: '24px' }}
                onClick={(e) => {
                  navigator.clipboard.writeText(user.metadata?.refCode);
                  context.setAlertOpts({
                    text: t('copied'),
                    icon: 'success',
                  });
                }}
              >
                {user.metadata?.refCode}
              </div>
            </div>}
          </div>
          <div className="col p-2 p-md-4 d-flex flex-column position-static">
            <strong className="d-inline-block mb-2 text-primary fs-5">{user.email}</strong>
            <h3 className="mb-0">{user.name}</h3>
            <p className="card-text mb-auto text-muted">{user.phone}</p>
            <div className="mt-3 text-wrap">
              <label>{t('tp-wallet-address')}</label><br />
              <div className={'badge text-white mb-2 bg-secondary'}>
                {user.metadata?.solana_wallet_address}
              </div>
              <span className="badge rounded-pill text-white bg-primary cursor-pointer"
                onClick={(e) => {
                  navigator.clipboard.writeText(user.metadata?.solana_wallet_address);
                  context.setAlertOpts({
                    text: t('copied'),
                    icon: 'success',
                  });
                }}
              >
                <i className="bi bi-clipboard"></i> {t('copy')}
              </span>
              <br />
              <label>{t('mu-in-wallet')}</label><br />
              <div className={'badge fs-5 text-white mb-2 ' + (balance === 0 ? 'bg-danger' : 'bg-success')}>
                {balance} TBT
              </div>
            </div>
            <div className="mt-3">
              <label>KYC {t('status')}</label><br />
              {user.metadata?.KYC_status !== 'completed' && <>
                <div className={'badge fs-5 text-uppercase text-white mb-2 bg-danger fade-in'}> <i className="bi bi-x-circle-fill"></i> {t(user.metadata.KYC_status)}</div>
                {!kycStatusReloading && <button type="button" class="border-0 bg-transparent text-secondary"
                  onClick={async (e) => {
                    e.preventDefault();
                    reloadKYC()
                  }}
                > <i className="bi bi-arrow-clockwise"></i></button>}
                {kycStatusReloading && <div class="spinner-border spinner-border-sm text-secondary ms-2" role="status"></div>}
                <br />
                <button type="button" class="btn btn-primary position-relative"
                  onClick={(e) => {
                    e.preventDefault();
                    zkMeWidget.launch();
                  }}
                >
                  <i className="bi bi-person-check"></i> {t('KYC-NOW')}
                  <span class="position-absolute top-0 start-100 translate-middle p-2 bg-danger rounded-circle spinner-grow spinner-grow-sm">
                  </span>
                </button>
                <button type="button" class="btn btn-primary position-relative ms-2 d-none"
                  onClick={(e) => {
                    e.preventDefault();
                    // getKYVisGrant();
                    handleFinished(JSON.parse(`{
  "status": "matching",
  "associatedAccount": "EpEBKmecfgPkcPjvXBJfqrXNb39KrUnxCuyP6Zs7fM1Y",
  "programNo": "202409270001",
  "verifyTimeAsIso": "2024-09-30T11:02:53.000Z",
  "isGrant": true,
  "verifyTime": 1727694173000
}`))
                  }}
                >
                  Check KYC
                </button>
              </>}
              {user.metadata?.KYC_status === 'completed' && <>
                <div className={'badge fs-5 text-uppercase text-white mb-2 bg-success fade-in'}> <i className="bi bi-check-circle-fill"></i> {user.metadata.KYC_status}</div><br />
              </>}
              <div className='d-none'>
                <br /><br />
                <label>KYC Result</label><br />
                <pre className='card card-body'>
                  {kycResult}
                </pre>
                <br />
              </div>
            </div>
            <div className="mt-3">
              <h4>{t('token-purchase-history-title')}</h4>
              <TransactionHist
                customerId={user.id}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
