import { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';

import {
  Col, Button, Form, FormControl, InputGroup, FormLabel, Spinner,
} from 'react-bootstrap';
import { emailPattern, namePattern } from '../common/constants';
import useAuth from '../hooks/useAuth';

import './signup.css';

function redirectPath(search) {
  const match = search.match(/redirect=(.*)/);
  const redirect = match?.[1];
  return redirect ? decodeURIComponent(redirect) : '/console';
}

function Signup() {
  const { t, i18n } = useTranslation();

  const title = t('signup-title');
  const context = useOutletContext();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { addUser, getSession } = useAuth();
  const [user, setUser] = useState(getSession())
  const navigate = useNavigate();
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm();
  // Watch the value of the password field
  const password = watch('password');

  useEffect(() => {
    if (user) {
      navigate(redirectPath(search));
    }
  }, [])

  const handleSignup = async (data) => {
    try {
      setIsLoading(true);
      const newUser = await addUser({ ...data, lang: i18n.language });
      // eslint-disable-next-line no-console
      console.log(`signup successful, user: ${newUser}`);
      context.setAlertOpts({
        text: 'Signup successful',
        icon: 'success',
        timer: 5000,
      });
      setIsLoading(false);
      navigate('/login');
    } catch (err) {
      context.setAlertOpts({
        text: err.message,
        icon: 'error',
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-signup mt-5 pt-5">
        <Form className="row g-2 card rounded-3 bg-tr-dark p-4" noValidate>
          <p className="fw-normal text-center">
            <Trans i18nKey="signup-text"></Trans>
            </p>
          <Form.Group as={Col} lg="12" controlId="inputFullName">
            <FormLabel>{t('signup-fullname')}</FormLabel>
            <FormControl type="text"
              isInvalid={errors.fullname}
              placeholder={t('signup-fullname')}
              {
              ...register('fullname', {
                required: true,
                pattern: namePattern,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.fullname?.type === 'required' && t('signup-err-01')}
              {errors.fullname?.type === 'pattern' && t('signup-err-02')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg="12" controlId="inputEmail">
            <FormLabel>{t('signup-email')}</FormLabel>
            <FormControl type="email"
              isInvalid={errors.email}
              placeholder="Email@domain.com"
              {
              ...register('email', {
                required: true,
                pattern: emailPattern,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.type === 'required' && t('signup-err-03')}
              {errors.email?.type === 'pattern' && t('signup-err-04')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="inputPhone">
            <Form.Label>{t('signup-phoneno')}</Form.Label>
            <Form.Control type="text"
              isInvalid={errors.phone}
              placeholder={t('signup-phoneno')}
              {...register('phone', { required: true })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone && t('signup-err-05')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg="12" controlId="inputPassword">
            <FormLabel>{t('signup-password')}</FormLabel>
            <FormControl type="password"
              isInvalid={errors.password}
              placeholder={t('signup-password')}
              {
              ...register('password', {
                required: true,
                minLength: 8,
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.password?.type === 'required' && t('signup-err-06')}
              {errors.password?.type === 'pattern' && t('signup-err-07')}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} lg="12" controlId="inputConfirmPassword">
            <FormLabel>{t('tp-confirm-purchase-btn')} {t('signup-password')}</FormLabel>
            <FormControl type="password"
              isInvalid={errors.confirmPassword}
              placeholder={`${t('tp-confirm-purchase-btn')} ${t('signup-password')}`}
              {
              ...register('confirmPassword', {
                required: true,
                minLength: 8,
                validate: (value) => (
                  value === password || t('signup-err-08')
                ),
              })
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && <span>{errors.confirmPassword.message}</span>}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              label={t('su-tnc-06')}
              feedback={t('signup-err-09')}
              feedbackType="invalid"
              isInvalid={errors.agree}
              {...register('agree', { required: true })}
            />
          </Form.Group>
          <Form.Group className="my-3">
            <Form.Check
              label={<>{t('su-tnc-01')}&nbsp;
                <a href="/tnc/Terms-of-Use" target="_blank">{t('su-tnc-02')}</a>,&nbsp;
                <a href="/tnc/Disclaimer" target="_blank">{t('su-tnc-03')}</a>{t('su-tnc-04')}&nbsp;
                <a href="/tnc/Privacy-Policy" target="_blank">{t('su-tnc-05')}</a>.</>}
              feedback={t('signup-err-09')}
              feedbackType="invalid"
              isInvalid={errors.agreeTnC}
              {...register('agreeTnC', { required: true })}
            />
          </Form.Group>
          <Button className="w-100 btn btn-lg btn-primary"
            type="button"
            disabled={isLoading}
            onClick={handleSubmit(handleSignup)}
          >
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">{t('signup-button')}</span>
          </Button>
        </Form>
      </main>
    </>
  );
}

export default Signup;
