import axios from 'axios';
import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Link, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TransactionHist from '../components/TransactionHist';
import useAuth from '../hooks/useAuth';

function BuyHist() {
  const { t } = useTranslation();

  const title = t('token-purchase-history-title');
  const { getSession } = useAuth();
  const user = getSession();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid p-0">
        <div
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 className="h2">{title}</h1>
        </div>
        <div className='card card-body my-3 col-12 col-md-6' style={{ minWidth: '320px' }} >
          <TransactionHist
            customerId={user.id}
          />
        </div>
      </div>
    </>
  );
}

export default BuyHist;
