import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import './btn.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import img1 from './images/about1.jpg';
import img2 from './images/about2.jpg';
import img3 from './images/about3.jpg';
import img4 from './images/about4.jpg';

const images = [
  img1,
  img2,
  img3,
  img4,
];

function About() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('about-title')}</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-4 pt-4 mt-5 row">

          <div className='col-sm-12 text-md-start text-center px-5 order-1'>
            <h1 className="display-5 fw-bold py-4 text-chaman">{t('about-title')}</h1>
          </div>

          <div className='col-md-5 col-sm-12 pt-0 px-md-5 order-md-2 order-3'>
            <p className="lead mb-4 fs-5 text-md-start text-center text-white pr-5">
            {t('about-welcome')}
            </p>
          </div>

          <div className="col-md-7 col-sm-12 pt-0 order-md-3 order-2">
            <div className="slideshow-container p-3 p-md-4 rounded-3 bg-tr-dark">
              <Slide arrows={false}>
                {images.map((i) => (
                  <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${i})` }}>
                    </div>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
        </div>
        <div className="px-1 px-md-5 py-2 my-0 row">
          <div className='mx-md-3 mx-sm-0 col-12 text-center text-md-start px-md-5 px-sm-0 py-1 rounded-3 bg-tr-dark'>
            <h1 className="display-5 fw-bold py-4 text-chaman">{t('about-membership-title')}</h1>
            <p className="lead mb-4 fs-5 text-white pr-5">
            {t('about-membership')}
              <br />
              <button className='button btn-dark my-3 px-3 py-2 rounded-pill'
                onClick={() => navigate('/blockchain')}
              > {t('buy-now')} </button>
            </p>
          </div>
        </div>
        <div className="w-100" style={{ height: '98px' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default About;
