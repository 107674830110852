import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
  faInstagram, faXTwitter, faLinkedin, faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import logo from './boss-logo.png';

function Footer() {
  const navigate = useNavigate();

  return (
    <footer className="p-4">
      <div className="row">
        <div className="col-md-6 col-3 ps-3">
          <a className=""
            onClick={() => navigate('/')}
          >
            <img src={logo} alt="" className="logo" />
          </a>
        </div>
        <div className="col-md-6 col-9 text-end justify-content-end d-flex">
          <div className='align-self-end pe-3'>Big Boss Generation |
            <I icon={faInstagram} className='ms-2' />
            <I icon={faXTwitter} className='ms-2' />
            <I icon={faLinkedin} className='ms-2' />
            <I icon={faFacebook} className='ms-2' />
          </div>
        </div>
        <hr className="mt-3" />
        <div className="col-12 col-md-6 col-3 px-3 text-center text-md-start">
          <a href="/tnc/Terms-of-Use" target="_blank" className='me-3'>Terms of Use</a>
          <a href="/tnc/Disclaimer" target="_blank" className='me-3'>Disclaimer</a>
          <a href="/tnc/Privacy-Policy" target="_blank">Privacy Policy</a>
        </div>
        <div className="col-md-6 col-12 text-end justify-content-end d-flex">
          <div className='align-self-end px-3 text-center text-md-start'>
            Copyright &copy; {new Date().getFullYear()} Big Boss Generation. <span className="text-nowrap">All rights reserved.</span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
