import React from 'react';
import {
  BrowserRouter, Navigate, Routes, Route,
} from 'react-router-dom';

import store from 'store';
import { useTranslation } from 'react-i18next';

import MainLayout from '../layouts/MainLayout';
import ConsoleLayout from '../layouts/ConsoleLayout';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Signup from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Apps from '../pages/Apps';
import Users from '../pages/Users';
import Settings from '../pages/Settings';
import AuthProvider from '../hooks/AuthProvider';
import About from '../pages/About';
import Buy from '../pages/Buy';
import BuyHist from '../pages/BuyHist';
import Contact from '../pages/Contact';
import Blockchain from '../pages/Blockchain';
import EventCal from '../pages/EventCal';
import ComingEvents from '../pages/ComingEvents';
import Tnc from '../pages/Tnc';

async function generateSHA256(input) {
  // Convert the input string into an array of bytes
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  // Generate the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the ArrayBuffer to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

function App() {
  const { i18n, t } = useTranslation();

  const lang = store.get('lang');
  if (lang && lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }

  const cec564f2fc07 = store.get('cec564f2fc07');

  const isUAT = window.location.hostname.split('.')[0] === 'uat';

  if (!isUAT || cec564f2fc07) {
    return (
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route path="" element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<Logout />} />
              <Route path="signup" element={<Signup />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route path="about" element={<About />} />
              { /* <Route path="buy" element={<Buy />} /> */}
              <Route path="buy" element={<Login />} />
              <Route path="contact" element={<Contact />} />
              <Route path="coming-events" element={<ComingEvents />} />
              <Route path="blockchain" element={<Blockchain />} />
              <Route path="tnc/:md" element={<Tnc />} />
              <Route path="not-found" element={<NotFound />} />
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Route>
            <Route path="console" element={<ConsoleLayout />}>
              <Route path="" element={<Dashboard />} />
              <Route path="buy/hist" element={<BuyHist />} />
              <Route path="buy/:paymentStatus" element={<Buy />} />
              <Route path="buy" element={<Buy />} />
              <Route path="profile" element={<Profile />} />
              <Route path="eventcal" element={<ComingEvents />} />
              { /* <Route path="apps" element={<Apps />} />
            <Route path="users" element={<Users />} /> */ }
              <Route path="settings" element={<Settings />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    );
  }
  return (
    <div className='w-100 vh-100 text-center fs-1 text-chaman position-relative'>
      <div className='position-absolute top-50 start-50 translate-middle'>
        Comming Soon
      </div>
      <div className='position-absolute bottom-0 end-0 fs-5'
        onClick={(e) => {
          e.preventDefault();
          const userPass = prompt('Enter Passcode');
          generateSHA256(userPass).then((hash) => {
            console.log(hash);
            if (hash === '8988ce7ae8572945b85cec564f2fc07abc33c48c9d0f2461401346949ed5d59e') {
              store.set('cec564f2fc07', true);
              window.location.reload();
            }
          })
        }}
      >
        <i className="bi bi-gear-fill"></i>
      </div>
    </div>
  )
}

export default App;
