import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Swal from 'sweetalert2'
import Footer from '../components/Footer';
import MainNavbar from '../components/MainNavbarOC';
import './main.css';

function MainLayout() {
  const { pathname } = useLocation();
  const [alertOpts, setAlertOpts] = useState({})
  const { t, i18n } = useTranslation();

  const handleDismiss = () => {
    alertOpts.current.isShow = false;
  };

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  function showCookiePopup() {
    // Check if the cookie has already been accepted or declined
    const cookiesAccepted = getCookie('cookiesAccepted');

    if (cookiesAccepted !== null) {
      console.log('Cookie preference already set:', cookiesAccepted);
      return; // Do not show the popup if cookie preference is already set
    }

    Swal.fire({
      title: i18n.language === 'en' ? 'We use cookies' : '我們使用Cookies',
      text: i18n.language === 'en' ? 'Our website uses cookies to improve your experience. Do you accept?' : '我們網站使用Cookies來提升我們的瀏覽體驗，你同意嗎？',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: i18n.language === 'en' ? 'Accept' : '同意',
      cancelButtonText: i18n.language === 'en' ? 'Decline' : '不同意',
      footer: '<a href="/tnc/Privacy-Policy" style="color: #cec2a4;">' + (i18n.language === 'en' ? 'Learn more about our cookie policy' : '了解更多關於我們的Cookie政策') + '</a>',
      background: '#000000DD', // Set the background color
      color: '#cec2a4', // Set the text color
      confirmButtonColor: '#cec2a4',
    }).then((result) => {
      if (result.isConfirmed) {
        // User accepted cookies
        document.cookie = 'cookiesAccepted=true; path=/; max-age=' + 60 * 60 * 24 * 365; // 1 year
      } else if (result.isDismissed) {
        // User declined cookies
        // document.cookie = 'cookiesAccepted=false; path=/; max-age=' + 60 * 60 * 24 * 365; // 1 year
      }
    });
  }

  useEffect(() => {
    if (alertOpts?.text && alertOpts.text !== '') {
      Swal.fire({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        ...alertOpts,
      })
    }
  }, [alertOpts]); // Run effect whenever the alertOpts changes

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0 });
      showCookiePopup();
    }
  }, [pathname]); // Run effect whenever the pathname changes

  return (
    <>
      <MainNavbar />
      <Outlet context={{ setAlertOpts }} />
      <Footer />
    </>
  );
}

export default MainLayout;
