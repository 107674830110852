import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';

import './btn.css';

function Contact() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{t('contact-title')}</title>
      </Helmet>
      <main className="container-fluid fade-in" id="about-main" >
        <div className="px-4 pt-5 mt-5 row">
          <div className='mx-md-5 mx-sm-0 col-12 text-justify px-md-5 px-sm-0 py-1 rounded-3 bg-tr-dark'>

            <h1 className="display-5 fw-bold py-4 text-chaman">{t('contact-background')}</h1>
            <p className="lead mb-4 fs-5 text-justify text-white ps-5">
              <Trans i18nKey="contact-background-text">
              </Trans>
            </p>
            <h3 className="fw-bold pt-4">{t('contact-email')}</h3>
            <p className="lead mb-4 fs-5 text-justify text-white ps-5">
              <a href="mailto:info@bigbossgeneration.com">info@bigbossgeneration.com</a>
            </p>
            <h3 className="fw-bold pt-4">{t('contact-address')}</h3>
            <p className="lead mb-4 fs-5 text-justify text-white ps-5">
              {t('contact-address-text')}
            </p>

          </div>
        </div>
        <div className="w-100" style={{ height: '498px' }}>&nbsp;</div>
      </main>
    </>
  );
}

export default Contact;
