import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from 'store';

// import './navbar.css';
import logo from './boss-logo.png';

function MainNavbarOC() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  // Function to change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const items = [
    { path: '/coming-events', title: t('upcoming-events-title') },
    { path: '/blockchain', title: t('membership-utility') },
    { path: '/login', title: t('login-title') },
    { path: '/about', title: t('home-about') },
    { path: '/contact', title: t('home-contact') },
  ];

  return (
    <nav className="navbar bg-body-tertiary fixed-top">
      <div className="container-fluid">
        <div className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate('/')}
        >
          <img src={logo} alt="Company Logo" className="me-3 img-fluid logo" />
          <div className="d-flex flex-column fs-5">
            <span className="fw-bold text-chaman">Big Boss</span>
            <span className="text-chaman">Generation</span>
          </div>
        </div>
        <div className='d-flex ms-auto'>
          <div className="button p-1 me-3 mt-2 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              changeLanguage(i18n.language === 'en' ? 'zh' : 'en');
              store.set('lang', i18n.language);
            }}
          >
            <i className="bi bi-globe"></i> {i18n.language === 'en' ? '中文' : 'English'}
          </div>

          <button className="navbar-toggler text-white p-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <i className="bi bi-list"></i>
          </button>
        </div>
        {/* Offcanvas menu */}
        <div className="offcanvas offcanvas-end" style={{ backgroundColor: 'var(--dark)' }} tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">{t('nav-menu')}</h5>
            <button type="button" className="text-white bg-tr-dark border-0" data-bs-dismiss="offcanvas" aria-label="Close"> X </button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              {
                items.map((item, i) => (
                  <li key={i} className="nav-item p-1 me-2 mb-1"
                    onClick={(e) => {
                      e.preventDefault();
                      document.querySelectorAll('.navbar-toggler')?.[0]?.click()
                    }}
                  >
                    <NavLink className="nav-link text-uppercase" to={item.path}>{item.title.replace('<br />', ' ')}</NavLink>
                  </li>
                ))
              }

            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MainNavbarOC;
