import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
import Footer from '../components/Footer';
import ConsoleNavbar from '../components/ConsoleNavbar';
import Sidebar from '../components/Sidebar';
import useAuth from '../hooks/useAuth';

import './main.css';

function ConsoleLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();

  const [alertOpts, setAlertOpts] = useState({})

  useEffect(() => {
    if (alertOpts?.text && alertOpts.text !== '') {
      Swal.fire({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        ...alertOpts,
      })
    }
  }, [alertOpts]); // Run effect whenever the alertOpts changes

  if (auth.isAuth()) {
    return (
      <>
        <ConsoleNavbar />
        <div className="container-fluid console">
          <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light collapse">
              <Sidebar />
            </nav>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-5 card" style={ { minHeight: '600px' } }>
              <Outlet context={{ setAlertOpts }} />
            </main>
              <Footer />
          </div>
        </div>
      </>
    );
  }

  return <Navigate to={`/login?redirect=${encodeURIComponent(pathname)}`} replace />;
}

export default ConsoleLayout;
